<template>
  <v-app>
    <div>
      <!--begin::Dashboard-->

      <div class="row">
        <div class="col-xl-12">
          <v-card
            class="mx-auto rounded-lg ecosistema-ve"
            elevation="10"
            height="100%"
            outlined
          >
            <v-card-title>
              <v-avatar size="56">
                <img
                  alt="Vehículo Eléctrico"
                  src="/media/cide/ecosistemas/VE.png"
                />
              </v-avatar>
              <h1 class="texto-ecosistema-ve">
                Vehiculo Eléctrico. {{ currentObject.nombre }}
              </h1>

              <v-spacer />
              <v-select
                v-model="selected"
                :items="$regions"
                item-text="nombre"
                item-value="slug"
                @change="changeComunidad"
              />

              <v-spacer />

              <router-link
                to="/vehiculo-electrico"
                class="btn btn-success font-weight-bolder font-size-sm"
              >
                Volver a los datos nacionales
              </router-link>
            </v-card-title>
            <v-card-text>
              <div class="row">
                <div class="col-xl-8" />
                <div class="col-xl-2">Nacional</div>
                <div class="col-xl-2">
                  {{ currentObject.nombre }}
                </div>
              </div>

              <div class="row">
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Contribución a la descarbonización del Vehículo Eléctrico
                    (Rural) 2020
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2020].nacional.ve }}
                  </span>
                  toneladas CO2 equivalente evitadas/10.000 habitantes
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2020].comunidad.ve }}
                  </span>
                  toneladas CO2 equivalente evitadas/10.000 habitantes
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Contribución a la descarbonización del Vehículo Eléctrico
                    (Rural) 2021
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2021].nacional.ve }}
                  </span>
                  toneladas CO2 equivalente evitadas/10.000 habitantes
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2021].comunidad.ve }}
                  </span>
                  toneladas CO2 equivalente evitadas/10.000 habitantes
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Contribución a la descarbonización del Vehículo Eléctrico
                    (Rural) 2022
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2022].nacional.ve }}
                  </span>
                  toneladas CO2 equivalente evitadas/10.000 habitantes
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2022].comunidad.ve }}
                  </span>
                  toneladas CO2 equivalente evitadas/10.000 habitantes
                </div>
              </div>
              <v-divider />
              <div class="row">
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Nº vehículos eléctricos registrados en zonas rurales 2020
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2020].nacional.nve }}
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2020].comunidad.nve }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Nº vehículos eléctricos registrados en zonas rurales 2021
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2021].nacional.nve }}
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2021].comunidad.nve }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Nº vehículos eléctricos registrados en zonas rurales 2022
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2022].nacional.nve }}
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2022].comunidad.nve }}
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-6">
          <v-card
            class="mx-auto rounded-lg"
            elevation="10"
            outlined
            height="100%"
          >
            <v-card-title>Penetración del vehículo eléctrico </v-card-title>
            <v-card-subtitle>
              haz click para saber más información sobre una Provincia en
              específico
            </v-card-subtitle>
            <v-card-text>
              <fusioncharts
                :type="currentObject.tipo_mapa"
                width="100%"
                height="500"
                data-format="jsonurl"
                :data-source="
                  '/data/VE/comunidad/' + currentSlug + '_mapa_inp01.json'
                "
              />
            </v-card-text>
          </v-card>
        </div>
        <div class="col-xl-6">
          <v-card
            class="mx-auto rounded-lg"
            elevation="10"
            outlined
            height="100%"
          >
            <v-card-title>
              Contribución a la descarbonización del Vehículo Eléctrico
            </v-card-title>
            <v-card-text>
              <fusioncharts
                type="msstackedcolumn2d"
                width="100%"
                height="500"
                data-format="jsonurl"
                :data-source="
                  '/data/VE/comunidad/' + currentSlug + '_inp01.json'
                "
              />
            </v-card-text>
          </v-card>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-4">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>Número de vehículos eléctrcios</v-card-title>

            <v-card-text>
              <fusioncharts
                type="msstackedcolumn2d"
                width="100%"
                height="400"
                data-format="jsonurl"
                :data-source="
                  '/data/VE/comunidad/' +
                  currentSlug +
                  '_tipovehiculo_inp04.json'
                "
              />
            </v-card-text>
          </v-card>
        </div>
        <div class="col-xl-4">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>
              Contribución a la descarbonización del Vehículo Eléctrico en zonas
              Rurales
            </v-card-title>
            <v-card-subtitle>
              (toneladas CO2 equivalente evitadas/10.000 habitantes)
            </v-card-subtitle>

            <v-card-text>
              <fusioncharts
                type="mscolumn2d"
                width="100%"
                height="400"
                data-format="jsonurl"
                :data-source="
                  '/data/VE/comunidad/' +
                  currentSlug +
                  '_tipovehiculo_inp01.json'
                "
              />
            </v-card-text>
          </v-card>
        </div>
        <div class="col-xl-4">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>
              Intención de adquisición de un vehículo eléctrico en el medio
              rural
            </v-card-title>

            <v-card-text>
              <fusioncharts
                type="msstackedcolumn2d"
                width="100%"
                height="400"
                data-format="jsonurl"
                :data-source="
                  '/data/VE/comunidad/' + currentSlug + '_inp06.json'
                "
              />
            </v-card-text>
          </v-card>
        </div>
      </div>

      <!--end::Dashboard-->
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      selected: this.$route.params.comunidad,
      selectedVariable:
        "Contribución a la descarbonización del Vehículo Eléctrico",
      selectedYear: "2020",
      selectedOption: "Todos",
      chartColumn: {
        type: "column2d",
        width: "100%",
        height: "400",
        dataFormat: "jsonurl",
      },
      chartMultipleColumn: {
        type: "mscolumn2d",
        width: "100%",
        height: "400",
        dataFormat: "jsonurl",
      },
      mapSpain: {
        type: "spain",
        width: "100%",
        height: "300",
        dataFormat: "jsonurl",
      },
      dataJson: [],
    };
  },
  computed: {
    currentSlug() {
      return this.$route.params.comunidad;
    },
    currentObject() {
      let selectedRegion = this.$regions.filter((comunidad) => {
        return comunidad.slug === this.$route.params.comunidad;
      });
      return selectedRegion[0];
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.getJsonData();
  },
  methods: {
    changeComunidad(selected) {
      this.$router.push(
        {
          name: "vehiculo-electrico-comunidad",
          params: { comunidad: selected },
        },
        () => {
          this.getJsonData();
        }
      );
    },
    getJsonData() {
      fetch("/data/VE/comunidad/" + this.currentSlug + "_data.json")
        .then((response) => response.json())
        .then((data) => {
          this.dataJson = data;
        });
    },
  },
};
</script>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
